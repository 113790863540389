import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      {/* <Header /> */}
      <MainFeature1
        subheading={<Subheading>Fuel Conversion:</Subheading>}
        heading='Comprehensive Fuel Conversion'
        buttonRounded={false}
        primaryButtonText='See Portfolio'
        imageSrc='https://nairametrics.com/wp-content/uploads/2023/07/convert-your-cars-to-run-on-natural-gas.jpg'
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading='State-of-the-Art Equipment Installation.'
        buttonRounded={false}
        primaryButtonText='Contact Us'
        imageSrc='https://www.heinepropane.com/site/wp-content/uploads/20589352_s-1.jpg'
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading='We Know Our Job.'
        description='Expertise and Experience: With years of experience in the field, our skilled professionals bring unmatched expertise to every conversion project.'
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
        ]}
        linkText=''
      />
      {/* <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} /> */}
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
